<template>
  <div :class="{ 'shrinkreport-content': drawer && !isMobile }" class="mt-16">
    <div>
      <Breadcrumbs class="inlineblock" />
    </div>

    <v-container>
      <v-row align="center"
        v-if="canManageMachineCalibrations"
        justify="center">
        <v-col md="8" sm="10" cols="12">
          <v-card class="align-center border10">
            <v-list>
              <v-subheader>{{ $t("general.engineering_department") }}</v-subheader>

              <SettingsItem
                :url="'/engineeringDepartments/listmachines'"
                :icon="'mdi-engine'"
                :title="$t('general.list_machines')"
              />
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";

import Breadcrumbs from "@/components/Breadcrumbs";
import SettingsItem from "@/components/menus/SettingsItem.vue";

export default {
  name: "EngineeringDepartments",
  components: {
    Breadcrumbs,
    SettingsItem,
  },
  data: () => {
      return {
         
      };
  },
  computed: {
    ...mapState({
      isMobile: (state) => state.isMobile,
      drawer: (state) => state.drawer,
    }),
    ...mapGetters({
      canManageMachineCalibrations: 'canManageMachineCalibrations'
    }),
  },
  methods: {
    ...mapActions({
      setBreadcrumbs: "setBreadcrumbs",
    }),
  },
  mounted() {
    this.setBreadcrumbs([{ text: this.$t("general.engineering_department"), href: "/engineeringDepartments" }]);
  },
};
</script>
<style scoped>
</style> 